import { useRecoilState } from 'recoil';

import { CurrentUserAtom } from '../atoms/current-user.atom';
import { GetCurrentUserResponse } from '../../../platform/server/api/auth/get-current-user/get-current-user.types';
import { useGetApi } from './use-get-api.hook';
import { setRedirectToUrlInSession } from '../../../platform/client/helpers/session.helpers';
import { useEffect } from 'react';
import { DbUserLoginMethod } from '../../server/database/db.types';
import { getFirstItem } from '../../common/helpers/array.helpers';
import { GA_USER_DATA_EVENT, registerDataLayer } from '../helpers/google-analytics';

export const useGetCurrentUser = () => {
  const [currentUser, setCurrentUser] = useRecoilState(CurrentUserAtom);

  const getCurrentUser = useGetApi<GetCurrentUserResponse>('get-current-user', {
    path: window.env.PLATFORM_URL + '/api/get-current-user',
    retry: 1,
  });

  useEffect(() => {
    if (getCurrentUser.isError) {
      if (
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/forgot-password' &&
        window.location.pathname !== '/reset-password' &&
        !window.location.pathname.startsWith('/cs')
      ) {
        setRedirectToUrlInSession(window.env.PLATFORM_URL + window.location.pathname);
        window.location.href = window.env.PLATFORM_URL + '/login';
      }

      if (window.location.pathname.startsWith('/cs') && currentUser.isAuthenticated) {
        window.location.reload();
      }
    }
  }, [currentUser.isAuthenticated, getCurrentUser.isError]);

  useEffect(() => {
    if (getCurrentUser.isPending || !getCurrentUser.data) {
      return;
    }

    const data = getCurrentUser.data;

    if (!data.isAuthenticated) {
      if (window.location.pathname !== '/login') {
        setRedirectToUrlInSession(window.env.PLATFORM_URL + window.location.pathname);
        window.location.href = `${window.env.PLATFORM_URL}/login`;
      }
      return;
    }

    if (data.loginMethod === DbUserLoginMethod.enum.password) {
      if (window.location.pathname === '/reset-password') {
        window.location.href = window.env.PLATFORM_URL;
        return;
      }

      if (data.isPasswordExpired && window.location.pathname !== '/update-password') {
        window.location.href = `${window.env.PLATFORM_URL}/update-password`;
        return;
      } else if (!data.isPasswordExpired && window.location.pathname === '/update-password') {
        window.location.href = window.env.PLATFORM_URL;
        return;
      }
    }

    if (window.location.pathname === '/login' || window.location.pathname === '/forgot-password') {
      window.location.href = window.env.PLATFORM_URL;
      return;
    }

    const emailData = data.email?.split('@') || [];
    const username = getFirstItem(emailData, '');
    const userDomain = emailData[1];
    const userCompany = userDomain ? getFirstItem(userDomain.split('.'), '') : '';

    registerDataLayer({
      dataLayer: {
        event: GA_USER_DATA_EVENT,
        email: data.email,
        full_name: data.fullName,
        username,
        user_company: userCompany,
      },
    });

    setCurrentUser(data);
  }, [
    currentUser.isAuthenticated,
    getCurrentUser.data,
    getCurrentUser.isError,
    getCurrentUser.isPending,
    setCurrentUser,
  ]);

  return currentUser;
};
